import * as Turbo from '@hotwired/turbo';
import '@hotwired/turbo-rails';
window.Turbo = Turbo;


import './utils/fetch.js';
import './utils/bootstrap.js';
import './utils/castle.js';
import './utils/tracker.js';
import './utils/adhunter.js';


import initSentry from './utils/sentry.js';
if (window.$Gunfinder.env !== 'development' && window.$Gunfinder.user.ua.supported) {
  initSentry('https://13504e9b86444989aa9927e19bb99755@o128241.ingest.sentry.io/5599126');
}

// PROBABLY UNNCESSARY, but we'll keep it for now
// window.requireCookies = () => {
//   if (!navigator.cookieEnabled) {
//     const modal = window.bootstrap.Modal.getOrCreateInstance(document.getElementById('no-cookies'));
//     modal.show();

//     if (window.Sentry) {
//       window.Sentry.captureMessage('cookies are disabled');
//     }

//     return false;
//   }

//   return true;
// };

document.addEventListener('turbo:render', () => {
  window.$Gunfinder.evalAdHunter();

  if (window.Trustpilot) {
    window.Trustpilot.Modules.WidgetManagement.findAndApplyWidgets();
  }
});

import './controllers';
