import { Dropdown, Modal, Popover } from 'bootstrap/dist/js/bootstrap.esm.min.js';
window.bootstrap = { Dropdown, Modal, Popover };

Dropdown.prototype.toggle = ((_orginal) => {
  return function () {
    if (this._parent.querySelectorAll('.dropdown').length > 0 && !this._parent.dataset.nestedDropdownsAttached) {
      this._parent.dataset.nestedDropdownsAttached = true;
      this._element.addEventListener('hide.bs.dropdown', (e) => {
        if (e.clickEvent?.target?.querySelectorAll(':scope > .dropdown-menu').length > 0 || e.clickEvent?.target?.parentNode.querySelectorAll(':scope > .dropdown-menu').length > 0) {
          e.preventDefault();
        }
      })
    }

    return _orginal.call(this);
  }
})(Dropdown.prototype.toggle);
