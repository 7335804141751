import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { platform: String, mobile: Boolean };

  async connect () {
    this.castleToken = await window.castle.createRequestToken();

    if(this.mobileValue) {
      this.platformValue === 'ios' ? this.handleIOS() : this.handleAndroid();
    } else {
      this.handleWeb();
    }
  }

  handleWeb () {
    this.element.querySelector('input[name="castle_request_token"]').value = this.castleToken;
    this.element.submit();
  }

  handleIOS () {
    const uri = `gunfinder:///mobile${window.location.pathname}${window.location.search}&castle_request_token=${this.castleToken}`;
    window.location.replace(uri);
  }

  handleAndroid () {
    const uri = `gunfinder:///mobile${window.location.pathname}${window.location.search}&castle_request_token=${this.castleToken}`;
    window.location.href = uri;
  }
}
